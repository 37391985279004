import React from "react"
import { graphql } from "gatsby"
import NewsCategory from "./news-category"

const Page = ({ data, pageContext }) => {
  return (
    <NewsCategory data={data} pageContext={pageContext} />
  )
}

export default Page

export const pageQuery = graphql`
  query($authorId: Int!, $skip: Int!, $limit: Int!) {
    posts: allWordpressNews(
      limit: $limit,
      skip: $skip,
      sort: {order: DESC, fields: date_gmt},
      filter: {author_id: {eq: $authorId}, status: {eq: "publish"}}
    ) {
      nodes {
        ...NewsItem
      }
    }

    author: wordpressAuthor(author_id: {eq: $authorId}) {
      ...AuthorFull
    }

    mainPage: wordpressMainPage(name: {eq: "news-superbom"}) {
      ...MainPageFull
    }
  }
`
